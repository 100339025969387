import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import TeamCards from "./TeamCards";

const Team = () => {
  return (
    <div className={globalstyles.wrapperContainer} id={"Team"}>
      <div className={globalstyles.headlineOsakeTilt}>
        Meet the leaders of UNCHAINED
      </div>
      <TeamCards />
    </div>
  );
};

export default Team;
