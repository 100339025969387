import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import styles from "./streamers.module.scss";
import githubLogo from "../../../../assets/svg/github.svg";
import twitterLogo from "../../../../assets/svg/twitter.svg";
import twitchLogo from "../../../../assets/svg/twitch.svg";
import youtubeLogo from "../../../../assets/svg/youtube.svg";
import discordLogo from "../../../../assets/svg/discord.svg";
import {streamerData} from "../../../../constants/teamData";

const StreamerCards = () => {
  return (
    <div className={styles.cardContainer}>
      {streamerData.map((item, index) => (
        <div className={styles.cardWrapper} key={index}>
          <img
            className={styles.cardImage + " " + globalstyles.cardBorderGlow}
            src={item.imageSource}
          />

          <p className={styles.cardName}>{item.cardName}</p>
          <p className={styles.cardProfession}>{item.cardProfession}</p>
          <div className={styles.horizontalLine}></div>
          <div className={styles.socialIconWrapper}>
            {item.githubUrl !== null ? (
              <a href={item.githubUrl} target={"_blank"} rel={"noreferrer"}>
                {" "}
                <img src={githubLogo} className={styles.icon} alt={" "} />
              </a>
            ) : null}
            {item.twitterUrl !== null ? (
              <a href={item.twitterUrl} target={"_blank"} rel={"noreferrer"}>
                {" "}
                <img src={twitterLogo} className={styles.icon} alt={" "} />
              </a>
            ) : null}
            {item.twitchUrl !== null ? (
                <a href={item.twitchUrl} target={"_blank"} rel={"noreferrer"}>
                  {" "}
                  <img src={twitchLogo} className={styles.icon} alt={" "} />
                </a>
            ) : null}
            {item.youtubeUrl !== null ? (
                <a href={item.youtubeUrl} target={"_blank"} rel={"noreferrer"}>
                  {" "}
                  <img src={youtubeLogo} className={styles.icon} alt={" "} />
                </a>
            ) : null}
            {item.discordUrl !== null ? (
                <a href={item.discordUrl} target={"_blank"} rel={"noreferrer"}>
                  {" "}
                  <img src={discordLogo} className={styles.icon} alt={" "} />
                </a>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StreamerCards;
