const faqData = [
  {
    title: "WHAT IS UNCHAINED?",
    description:
      "Unchained is a web3 gaming guild, initially founded to rule the world of Ravenquest and Immutable zkEVM.",
  },
  {
    title: "WEN AIRDROP?",
    description:
        "Our guild does occasional free airdrops for all our members. Join us and stay tuned for the next one."
  },
  {
    title: "HOW CAN I JOIN?",
    description:
        "Contact @Fugashu or @mrtom4858 on Discord."
  },


];

export default faqData;
