import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import styles from "./teamcard.module.scss";
import githubLogo from "../../../../assets/svg/github.svg";
import twitterLogo from "../../../../assets/svg/twitter.svg";
import {leaderData} from "../../../../constants/teamData";

const TeamCards = () => {
  return (
    <div className={styles.cardContainer}>
      {leaderData.map((item, index) => (
        <div className={styles.cardWrapper} key={index}>
          <img
            className={styles.cardImage + " " + globalstyles.cardBorderGlow}
            src={item.imageSource}
          />

          <p className={styles.cardName}>{item.cardName}</p>
          <p className={styles.cardProfession}>{item.cardProfession}</p>
          <div className={styles.horizontalLine}></div>
          <div className={styles.socialIconWrapper}>
            {item.githubUrl !== null ? (
              <a href={item.githubUrl} target={"_blank"} rel={"noreferrer"}>
                {" "}
                <img src={githubLogo} className={styles.icon} alt={" "} />
              </a>
            ) : null}
            {item.twitterUrl !== null ? (
              <a href={item.twitterUrl} target={"_blank"} rel={"noreferrer"}>
                {" "}
                <img src={twitterLogo} className={styles.icon} alt={" "} />
              </a>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamCards;
