import React from "react";
import styles from "./commonHeaderComponents.module.scss";
import logo from "../assets/png/logo.png";

const HeaderLogoAndText = () => {
  return (
    <a className={styles.logoTextWrapper} href={"/"}>
      <img className={styles.headerLogo} src={logo} alt={""} />
    </a>
  );
};

export default HeaderLogoAndText;
