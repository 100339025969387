import React, { useEffect, useState } from "react";
import globalstyles from "./styles/global.module.scss";
import { useMediaQuery } from "react-responsive";
import Navbar from "./pages/LandingPage/MainSection/NavbarContainer/Navbar";
import BurgerMenu from "./pages/LandingPage/MainSection/BurgerMenu/BurgerMenu";
import Unchained from "./pages/LandingPage/MainSection/Unchained/Unchained";
import ParallaxLanding from "./pages/LandingPage/ParallaxLanding/ParallaxLanding";
import Team from "./pages/LandingPage/MainSection/Team/Team";
import Footer from "./pages/LandingPage/MainSection/Footer/Footer";
import FAQ from "./pages/LandingPage/MainSection/FAQ/FAQ";
import CallToAction from "./pages/LandingPage/MainSection/CallToAction/CallToAction";
import TransitionContainer from "./pages/LandingPage/MainSection/TransitionContainer/TransitionContainer";
import PreLoader from "./components/PreLoader/PreLoader";
import clsx from "clsx";
import styles from "./app.module.scss";
import Games from "./pages/LandingPage/MainSection/Games/Games";
import Streamers from "./pages/LandingPage/MainSection/Streamers/Streamers";
import Partners from "./pages/LandingPage/MainSection/Partners/Partners";

const App = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1400px)",
  });
  const [playAnimation, setPlayAnimation] = useState(false);

  // This will run one time after the component mounts
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        setPlayAnimation(true);
      }, 1000);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);

      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div
      className={clsx({
        [styles.app]: true,
        [styles.app_hide]: playAnimation,
      })}
      id="outer-container"
    >
      <div
        className={clsx({
          [styles.fullScreen]: true,
          [styles.fullScreen_hide]: playAnimation,
        })}
      >
        <PreLoader />
      </div>

      {isMobile ? (
        <BurgerMenu
          pageWrapId={"page-wrap"}
          outerContainerId={"outer-container"}
        />
      ) : (
        <Navbar />
      )}
      <div id={"page-wrap"} className={"main-content"}>
        {isMobile ? <ParallaxLanding /> : <ParallaxLanding />}
        <div id={"main-content"} className={globalstyles.mainContent}>
          <TransitionContainer />
          <Unchained />
          <Partners/>
          <Games />
          <Team />
          <Streamers />

          <FAQ />
          <CallToAction />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
