import React from 'react';
import styles from './transition.module.scss'
const TransitionContainer = () => {
    return (
        <div className={styles.transition}>

        </div>
    );
};

export default TransitionContainer;
