import React from "react";
import styles from "./commonHeaderComponents.module.scss";
import socialIcons from "../constants/socialIcons";
import { toast } from "react-toastify";

const SocialIcons = () => {
  return (
    <div className={styles.headerIcons}>
      {socialIcons.map((icon, index) => (
        <div className={styles.headerIconWrapper} key={index}>
          {icon.redirect ? (
            <a
              rel="noreferrer"
              key={index}
              href={icon.redirect}
              target={"_blank"}
            >
              <img className={styles.headerIcon} src={icon.imageSrc} alt={""} />
            </a>
          ) : (
            <div
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
              }}
              onClick={() => {
                toast.warning(icon.dummyText);
              }}
            >
              <img className={styles.headerIcon} src={icon.imageSrc} alt={""} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SocialIcons;
