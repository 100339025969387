import React from "react";

import logo from "../../../../assets/png/logo.png";
import styles from "./unchained.module.scss";
import globalstyles from "../../../../styles/global.module.scss";

const Unchained = () => {
  return (
    <div className={globalstyles.wrapperContainer} id={"The Unchained Tale"}>
      <div className={styles.centerImage}>
        <img src={logo} alt={"Unchained Logo"} width={'30%'}/>
      </div>
      <div className={styles.textWrapper}>
        <p className={globalstyles.headlineOsakeTilt}>The tale of Unchained</p>
          <p className={globalstyles.centeredMainText}>
              In the vast digital landscapes of the Metaverse, where chaos and opportunity collide, a new power emerged.
              A collection of warriors unmatched in skill and prowess; they had no master, no ruler, and no limits, thus
              Unchained was born.
              <br/>
              What began as a simple partnership quickly grew into something much larger. With a deep understanding of
              both the blockchain and gaming realms, they harnessed their collective skills to overcome all before them.
              Word of their triumphs spread like wildfire attracting wanderers, warriors, and adventurers eager to
              join their cause. <br/>

              In just a few short months, Unchained evolved into a force to be reckoned with. More than 2k like-minded
              individuals now called it home, each with unique talents and abilities. Together, they sought to remake
              the world around them and expand their influence across Web3.
              <br/>
              Yet, despite their growing numbers and success, Unchained never forgot its origins; strangers, bound not
              by contracts or control but by a shared vision of decentralization, freedom, and power in the Metaverse.
              They embraced the fluid, ever-changing nature of both the blockchain and the games they played, knowing
              that the true essence of the guild lays in its name. No one could control them—Unchained, they would
              remain.
          </p>

      </div>
    </div>
);
};

export default Unchained;
