import fugashu from "../assets/team/fugashu.webp";
import kingtom from "../assets/team/kingtom.webp";
import kittymonster from "../assets/team/kittymonster.webp";

import {jonasGithubUrl, jonasXUrl, kingtomXUrl, kittymonsterXUrl} from "./redirects";
import toñin from "../assets/streamers/toñin.webp";
import metox from "../assets/members/metox.webp";
import chewbacca from "../assets/members/chewbacca.webp";
import jaeger from "../assets/members/jaeger.webp";
import duckel from "../assets/members/duckel.webp";
import jhawk from "../assets/members/jhawk.webp";
import thedragondaniel from "../assets/members/thedragondaniel.webp";
import jami from "../assets/members/jami.webp";
import reborn from "../assets/members/reborn.webp";
import samsosmooth from "../assets/members/samsosmooth.webp";
import phar from "../assets/members/phar.webp";
import oskari from "../assets/members/oskari.webp";
import sherygames from "../assets/members/sherygames.webp";
import biobee from "../assets/members/biobee.webp";
import nero from "../assets/members/nero.webp";
import urdhi from "../assets/members/urdhi.webp";
import wasaki from "../assets/members/wasaki.webp";
import azurak from "../assets/members/azurak.webp";
import solidi from "../assets/members/solidi.webp";
import lightblue from "../assets/members/lightblue.webp";
import MeMikko from "../assets/members/MeMikko.webp";
import Web3GamingTv from "../assets/members/Web3GamingTv.webp";
import KungCao from "../assets/members/KungCao.webp";
import mandrako from "../assets/members/mandrako.webp";
import valdiz from "../assets/members/valdiz.webp";
import FaroTV from "../assets/members/FaroTV.webp";
import OtsogaGaming from "../assets/members/OtsogaGaming.webp";
import Tailless from "../assets/members/Tailless.webp";
import Vigilant from "../assets/members/Vigilant.webp";
import ToxiC55 from "../assets/members/ToxiC55.webp"
import PanzerUDL from "../assets/members/PanzerUDL.webp"


export const memberData = [
  {
    imageSource: chewbacca,
    cardName: "Chewbacca",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/RealChewbacca11",
  },
  {
    imageSource: metox,
    cardName: "metox",
    cardProfession: null,
    githubUrl: "https://github.com/Metooox",
    twitterUrl: "https://x.com/Metoooooox",
  },
  {
    imageSource: jhawk,
    cardName: "Jhawk",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/aragonnnj",
  },
  {
    imageSource: duckel,
    cardName: "Duckel",
    githubUrl:null,
    cardProfession: null,
    twitterUrl: "https://x.com/Duckel_",
  },{
    imageSource: jaeger,
    cardName: "JAEGER",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/Fun_J_J",
  },{
    imageSource: thedragondaniel,
    cardName: "Daniel",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/theDragonDanie1",
  },
  {
    imageSource: jami,
    cardName: "JaMi",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/K0_F0",
  },{
    imageSource: samsosmooth,
    cardName: "satdragon",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/samsosmooth2",
  },{
    imageSource: reborn,
    cardName: "Reborn",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/ilv_enjoyer",
  },
  {
    imageSource: phar,
    cardName: "Phar",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/phar_gaming",
  },
  {
    imageSource: oskari,
    cardName: "Oskari",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/cryptosgren",
  },
  {
    imageSource: sherygames,
    cardName: "SheryGames",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/SheryGames_",
  },
  {
    imageSource: biobee,
    cardName: "Biobee",
    cardProfession: null,
    githubUrl: "https://github.com/Prabu91",
    twitterUrl: "https://x.com/biobee90",
  },{
    imageSource: nero,
    cardName: "Nero",
    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/X_N3R0",
  },{
    imageSource: wasaki,
    cardName: "Wasaki",    cardProfession: null,
    githubUrl:null,
    twitterUrl: "https://x.com/WasakiYT",
  },{
    imageSource: urdhi,
    cardName: "Urdhi",    cardProfession: null,

    githubUrl:null,
    twitterUrl: "https://x.com/TheRealUrdhi",
  },
  {
    imageSource: lightblue,
    cardName: "lightblue",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/blankopirata",
  },
  {
    imageSource: solidi,
    cardName: "Solidi",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/solidmag3",
  },
  {
    imageSource: mandrako,
    cardName: "Mandrako",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/MandrakitoW",
  },
  {
    imageSource: PanzerUDL,
    cardName: "PanzerUDL",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/curios_art",
  },

];


export const leaderData = [
  {
    imageSource: fugashu,
    cardName: "Fugashu",
    cardProfession: "Fullstack Dev",
    githubUrl: jonasGithubUrl,
    twitterUrl: jonasXUrl,
  },
  {
    imageSource: kittymonster,
    cardName: "Kittymonster",
    cardProfession: "Community Lead",
    githubUrl: null,
    twitterUrl: kittymonsterXUrl,
  },
  {
    imageSource: kingtom,
    cardName: "Kingtom",
    cardProfession: "Partnerships",
    githubUrl: null,
    twitterUrl: kingtomXUrl,
  },

];


export const streamerData = [
  {
    imageSource: toñin,
    cardName: "Tonin",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/tonin_eth",
    twitchUrl: "https://www.twitch.tv/tonin_eth",
    youtubeUrl: "https://www.youtube.com/@Tonin_eth",
    discordUrl: null,
  },
  {
    imageSource: azurak,
    cardName: "Azurak",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/Cromulux",
    twitchUrl: "https://www.twitch.tv/azurakucd",
    youtubeUrl: null,
    discordUrl: null
  },
  {
    imageSource: KungCao,
    cardName: "KungCao",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/kungcao_eth",
    twitchUrl: "https://www.twitch.tv/kungcao",
    youtubeUrl: "https://www.youtube.com/@kungcao_eth",
    discordUrl: null,
  },
  {
    imageSource: Web3GamingTv,
    cardName: "Web3GamingTv",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/Web3GamingTv",
    twitchUrl: "https://www.twitch.tv/web3gamingtv",
    youtubeUrl: "https://www.youtube.com/@web3gamingtv",
    discordUrl: null,
  },
  {
    imageSource: MeMikko,
    cardName: "MeMikko",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/CryptoMikko",
    twitchUrl: "https://www.twitch.tv/memikko",
    youtubeUrl: "https://www.youtube.com/@memikko",
    discordUrl: null,
  },
  {
    imageSource: valdiz,
    cardName: "Valdiz",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://twitter.com/valdiz_gu",
    twitchUrl: "https://www.twitch.tv/valdiz_gu",
    youtubeUrl: "https://www.youtube.com/@valdiz_gu",
    discordUrl: "https://discord.com/invite/FYwqkmxuXC",
  },
  {
    imageSource: Tailless,
    cardName: "Tailless",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/Tailless_8",
    twitchUrl: "https://www.twitch.tv/tailless8",
    youtubeUrl: "https://www.youtube.com/@tailless8",
    discordUrl: null,
  },
  {
    imageSource: FaroTV,
    cardName: "Faro TV",
    cardProfession: null,
    githubUrl: null,
    twitchUrl: "https://www.twitch.tv/faro_tv",
    youtubeUrl: "https://www.youtube.com/@FaroTV_",
    discordUrl: "https://discord.gg/6tWrqQB",
    twitterUrl: "https://x.com/Faro95717593"
  },
  {imageSource: OtsogaGaming,
    cardName: "OtsogaGaming",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/AAnreyn",
    twitchUrl: "https://www.twitch.tv/otsogagaming",
    youtubeUrl: "https://www.youtube.com/@otsogagaming3872",
    discordUrl: null
  },
  {
    imageSource: Vigilant,
    cardName: "Vigilant",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/0xbugraerdal",
    twitchUrl: "https://www.twitch.tv/Vigilantvv",
    youtubeUrl: null,
    discordUrl: "https://discord.gg/afyRyQBEph",
  },
  {
    imageSource: ToxiC55,
    cardName: "ToxiC55",
    cardProfession: null,
    githubUrl: null,
    twitterUrl: "https://x.com/ToxiC5501",
    twitchUrl: "https://www.twitch.tv/toxic553",
    youtubeUrl: "https://www.youtube.com/@ToxiC55",
    discordUrl: "https://discord.gg/2kpsVx46pb",
},




];
