import React, { useEffect, useState } from "react";
import styles from "./navbar.module.scss";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import SocialIcons from "../../../../components/socialIcons";
import HeaderLogoAndText from "../../../../components/headerLogoAndText";
import clsx from "clsx";
import LaunchButtonNavbar from "../../../../components/LaunchButton/LaunchButtonNavbar";
import {discordUrl} from "../../../../constants/redirects";
const Navbar = () => {
  const [links, setLinks] = useState([
    { name: "The Unchained Tale", isActive: false },
    { name: "Games", isActive: false },
    { name: "Team", isActive: false },
    { name: "Faq", isActive: false },
  ]);

  const location = useLocation();

  React.useEffect(() => {
    setLinks(
      links.map((link) => {
        return window.location.hash.replaceAll("%20", " ") ==
          `#/#${link.name.toString()}`
          ? { ...link, isActive: true }
          : link;
      })
    );
  }, [location]);
  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 1200;
      if (show) {
        setNavBackground(true);
      } else {
        setNavBackground(false);
      }
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [navBackground, setNavBackground] = useState(false);

  return (
    <div
      className={clsx(styles.headerContainer, navBackground && styles.navSolid)}
    >
      <HeaderLogoAndText />
      <div className={styles.actionButtonWrapper}>
        <LaunchButtonNavbar text={"Discord"} linkTo={discordUrl} />
      </div>
      <div className={styles.linksContainer}>
        {links.map((link, index) => (
          <HashLink
            className={
              window.location.hash.replaceAll("%20", " ") ===
              "#/#" + link.name.toString()
                ? styles.navActive
                : styles.navInactive
            }
            key={index}
            to={`/#${link.name}`}
            smooth={true}
          >
            <p className={styles.linkText}>{link.name}</p>
          </HashLink>
        ))}
      </div>

      <SocialIcons />
    </div>
  );
};

export default Navbar;
