import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AccordionContext } from "./AccordionWrapper";
import styles from "./accordion.module.scss";
import clsx from "clsx";

const AccordionItem = (props: {
  index: number;
  title:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
  description:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  let indexPlus;

  const indexCount = (index: number) => {
    indexPlus = index + 1;
    return indexPlus;
  };

  const { active, setActive } = useContext(AccordionContext);

  const eventHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: any
  ) => {
    e.preventDefault();
    setActive(index);
  };

  return (
    <div className={styles.accordionItem}>
      <h3 className={styles.accordionTitle}>
        <button
          onClick={(e) => eventHandler(e, props.index)}
          className={active === props.index ? styles.active : styles.inactive}
          aria-expanded={active === props.index ? "true" : "false"}
          aria-controls={"sect-" + indexCount(props.index)}
          aria-disabled={active === props.index ? "true" : "false"}
        >
          <span className={styles.titleWrapper}>{props.title}</span>
          <span className={styles.iconWrapper}>
            <span
              className={active === props.index ? styles.plus : styles.minus}
            ></span>
          </span>
        </button>
      </h3>
      <div className={styles.accordionPanel}>
        <div
          id={"sect-" + indexCount(props.index)}
          className={clsx(
            styles.text,
            active === props.index ? styles.panelOpen : styles.panelClose
          )}
        >
          {props.description}
        </div>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AccordionItem;
