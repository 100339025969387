import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import AccordionWrapper from "./AccordionWrapper";
import AccordionItem from "./AccordionItem";
import faqData from "../../../../constants/faqData";
import styles from "./accordion.module.scss";
import faqMinotaurImage from "../../../../assets/png/faqmino-min.png.webp";
import { useMediaQuery } from "react-responsive";
const FAQ = () => {
  const isMobile = useMediaQuery({
    query: "(max-width: 1224px)",
  });

  return (
    <div className={globalstyles.wrapperContainer} id={"Faq"}>
      <p className={globalstyles.headlineOsakeTilt}>Faq</p>
      <div className={styles.faqContainer}>
        {isMobile ? null : (
          <div>
            <img src={faqMinotaurImage} width={500} />
          </div>
        )}

        <AccordionWrapper>
          {faqData.map((item, index) => (
            <AccordionItem
              key={index}
              index={index}
              title={item.title}
              description={item.description}
            />
          ))}
        </AccordionWrapper>
      </div>
    </div>
  );
};

export default FAQ;
