import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import styles from "./footer.module.scss";

const Footer = () => {
  return (
    <div className={styles.wrapperContainer}>
      <div className={styles.legalWrapper}>
        <p
          className={globalstyles.leftAlignedMainText + " " + styles.copyright}
        >
          © 2024 Unchained. Website created by <a style={{marginLeft:'5px'}} href={'https://x.com/fugashu_codes'}><br/>Fugashu</a>
        </p>

      </div>
    </div>
  );
};

export default Footer;
