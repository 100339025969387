import React from "react";
import { animated, useSpring } from "react-spring";
import "./parallax.scss";
import styles from "./parallax.module.scss";


const ParallaxLanding = () => {
  const castParallax = () => {
    window.addEventListener("scroll", function (event) {
      const top = window.pageYOffset;
      const layers = document.getElementsByClassName("parallax");
      for (let i = 0; i < layers.length; i++) {
        const layer = layers[i];
        const speed = layer.getAttribute("data-speed");
        if (speed === null) {
          return;
        }
        const yPos = -((top * parseFloat(speed)) / 100);
        layer.setAttribute(
          "style",
          "transform: translate3d(0px, " + yPos + "px, 0px)"
        );
      }
    });
  };
  castParallax();

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 400,
    config: { duration: 3000 },
  });
  return (
    <div>
      <div className="keyart" id="parallax">
        <div
            className="keyart_layer parallax"
            id="keyart-1"
            data-speed="11"
        ></div>
        <div
            className="keyart_layer parallax"
            id="keyart-2"
            data-speed="16"
        ></div>
        <div
            className="keyart_layer parallax"
            id="keyart-3"
            data-speed="26"
        ></div>
        <div
            className="keyart_layer parallax"
            id="keyart-4"
            data-speed="36"
        ></div>
        <div
            className="keyart_layer parallax"
            id="keyart-5"
            data-speed="49"
        ></div>
        <div className="keyart_layer" id="keyart-scrim"></div>
        <div
            className="keyart_layer parallax"
            id="keyart-6"
            data-speed="69"
        ></div>
        <div className="keyart_layer" id="keyart-7" data-speed="100"></div>
        <animated.div className={styles.logoText} style={props}>
          <p>Unchained Guild Group</p>
        </animated.div>
        {/* <animated.div className={styles.giveawayText} style={props}>*/}
        {/*  <p style={{textAlign: 'center'}}>1st Place - Ravenquest Munk</p>*/}
        {/*  <p style={{textAlign: 'center'}}>2-4th Place - Landsale Whitelist</p>*/}
        {/* </animated.div>*/}


      </div>
    </div>
  );
};

export default ParallaxLanding;
