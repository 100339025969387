export const discordUrl = 'https://discord.gg/6n6mJpkcNk';
export const xUrl = 'https://x.com/Unchained_Guild';

export const ravenquestRefLink = 'https://ravenquest.io/register/ref/15179'
export const gamersGalaxie = 'https://discord.gg/2Gc8bS33zR'
export const comfortZone = 'https://x.com/CZ_PIXELS'
export const kittymonsterXUrl = 'https://x.com/kittyymonsterr'
export const pixels = 'https://www.pixels.xyz/'

export const jonasGithubUrl = "https://github.com/Fugashu";
export const jonasXUrl = "https://x.com/fugashu_codes";
export const kingtomXUrl = "https://x.com/KingTom_web3";

