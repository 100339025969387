import React from "react";
import Lottie from "lottie-react";
import blocks from "../../assets/lottie/minotaur.json";
import styles from "./preloader.module.scss";

const PreLoader = () => {
  return (
    <div className={styles.wrapper}>
      <Lottie animationData={blocks} loop={true} />
    </div>
  );
};

export default PreLoader;
