import React from "react";
import styles from "../Footer/footer.module.scss";
import globalstyles from "../../../../styles/global.module.scss";
import LaunchButton from "../../../../components/LaunchButton/LaunchButton";
import discord from "../../../../assets/svg/discord.svg";
import {discordUrl } from "../../../../constants/redirects";

const CallToAction = () => {
  return (
    <div className={globalstyles.wrapperContainer}>
      <p className={globalstyles.headlineOsakeTilt}>Join Unchained now!</p>
      <div className={styles.ctaButtonWrapper}>

        <LaunchButton
          text={"Discord"}
linkTo={discordUrl}          logo={discord}
        />

      </div>
    </div>
  );
};

export default CallToAction;
