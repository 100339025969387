import React from "react";

import globalstyles from "../../../../styles/global.module.scss";
import styles from "./games.module.scss";
import ravenquestLogo from "../../../../assets/games/logo_ravenquest.png";
import pixelsLogo from "../../../../assets/games/pixels_logo.webp";

import {pixels, ravenquestRefLink} from "../../../../constants/redirects";
const Games = () => {


  return (
      <div className={globalstyles.wrapperContainer} id={"Games"}>
          <div className={globalstyles.headlineOsakeTilt}>
              Our active Games
          </div>
          <div className={styles.cardWrapper}>
              <a href={ravenquestRefLink} target={"_blank"} rel={'noreferrer'}>
                  <img
                      className={styles.cardImage + " " + globalstyles.cardBorderGlow}
                      src={ravenquestLogo}
                      alt={'ravenquest logo'}/>
              </a>
              <a href={pixels} target={"_blank"} rel={'noreferrer'}>
                  <img
                      className={styles.cardImage + " " + globalstyles.cardBorderGlow}
                      src={pixelsLogo}
                      alt={'pixels logo'}/>
              </a>


          </div>
      </div>
  );
};

export default Games;
