import React from "react";
import globalstyles from "../../../../styles/global.module.scss";
import StreamerCards from "./StreamerCards";

const Streamers = () => {
  return (
    <div className={globalstyles.wrapperContainer} id={"Content Creators"}>
      <div className={globalstyles.headlineOsakeTilt}>
        CONTENT CREATORS
      </div>
      <StreamerCards />
    </div>
  );
};

export default Streamers;
