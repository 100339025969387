import React, { createContext, useState } from "react";
import styles from './accordion.module.scss'

export const AccordionContext = createContext({
  active: 0,
  setActive: (index: any) => {},
});

const AccordionWrapper = (props: {
  children:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) => {
  const [active, setActive] = useState(0);

  return (
    <AccordionContext.Provider
      value={{
        active,
        setActive,
      }}
    >
      <div className={styles.accordionWrapper}>
        <form>
          {props.children}
        </form>
      </div>
    </AccordionContext.Provider>
  );
};

export default AccordionWrapper;
