import discordIcon from "../assets/svg/discord.svg";
import xIcon from "../assets/svg/twitter.svg";

import {discordUrl, xUrl} from "./redirects";

const socialIcons = [
  {
    imageSrc: xIcon,
    redirect: xUrl,
    dummyText: "",
  },

  {
    imageSrc: discordIcon,
    redirect: discordUrl,
    dummyText: "",
  },
  // {
  //   imageSrc: openSeaIcon,
  //   redirect: openSeaUrl,
  //   dummyText: "Our Collection is not yet on OpenSea. Do not get scammed!",
  // },
];

export default socialIcons;
