import React, { useState } from "react";
import { push as Menu } from "react-burger-menu";
import "./BurgerMenue.scss";
import styles from "../../../../styles/global.module.scss";
import { HashLink } from "react-router-hash-link";

import LaunchButtonNavbar from "../../../../components/LaunchButton/LaunchButtonNavbar";
import {discordUrl} from "../../../../constants/redirects";
import {xUrl} from "../../../../constants/redirects";

// eslint-disable-next-line react/display-name
export default (props: {
  pageWrapId: string | undefined;
  outerContainerId: string | undefined;
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };
  return (
    <Menu
      right
      pageWrapId={props.pageWrapId}
      outerContainerId={props.outerContainerId}
      isOpen={isOpen}
      onOpen={handleIsOpen}
      onClose={handleIsOpen}
    >
      <HashLink
        className={styles.leftText}
        to={`/#The Unchained Tale`}
        smooth={true}
        onClick={closeSideBar}
      >
        The Tale
      </HashLink>
      <HashLink
          className={styles.leftText}
          to={`/#Games`}
          smooth={true}
          onClick={closeSideBar}
      >
        Games
      </HashLink>

      <HashLink
        className={styles.leftText}
        to={`/#Team`}
        smooth={true}
        onClick={closeSideBar}
      >
        Team
      </HashLink>
      <HashLink
        className={styles.leftText}
        to={`/#Faq`}
        smooth={true}
        onClick={closeSideBar}
      >
        FAQ
      </HashLink>
      <LaunchButtonNavbar text={'Discord'} linkTo={discordUrl}/>
      <LaunchButtonNavbar text={'X'} linkTo={xUrl}/>

    </Menu>
  );
};
